import Link from 'next/link';
import React from "react";
import Image from 'next/image';
import {useEffect, useState} from "react";
import {ctaEvent} from "../../../utils/events";

export async function getStaticProps(context) {
    return {
        props: {}, // will be passed to the page component as props
    }
}

export default function Header() {

    return (
        <>
            <div className="header-container">
                <div className="row">
                    <div className="col-md-12">

                        <h1>EXTEND YOUR TAX RETURN THROUGH <span>OCTOBER 15th</span></h1>
                        <p>An IRS Tax Extension Gives you More Time for Your Taxes</p>
                        <a className="btn btn-default cta-btn" href="/submit-extension" onClick={() => ctaEvent("hero-cta")} id="hero-cta">Submit Tax Extension</a>
                    </div>
                </div>
            </div>
            <div className="inner-header-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Did you know that you can easily extend your tax return from wherever you can
                            access the
                            internet?</h2>
                        <hr/>
                        <p>By working with an authorized E-file provider, you can have confidence in the
                            security of
                            your data, and the peace of mind that your federal extension is submitted
                            immediately, for a
                            modest fee. Answer a few questions and you can elect to file your extension
                            electronically,
                            at your convenience, or we will show you how you can submit it for free!</p>
                        <small>If you need more help, please read our&nbsp;
                            <Link
                                href="/faq" prefetch={false}>FAQ</Link>.</small>
                    </div>
                    <div className="col-md-6">
                        <Image className="pull-right" src="/images/thumb1.jpg"
                               layout={"responsive"}
                               width="540" height="364"
                               alt="Woman filling tax forms"
                               style={{maxWidth: "100%"}}/>
                    </div>
                </div>
            </div>
        </>
    )
}
